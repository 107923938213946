<template>
  <div class="w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="mb-0">{{ $t('managment') | toCapitalize }}</h5>
      <div class="text-right">
        <b-button v-if="hideDt" class="mr-3" @click="openModalDts('dt')">{{ $t('add') }} DT</b-button>
        <b-button v-if="hideCoDt" @click="openModalDts('codt')">{{ $t('add') }} CO-DT</b-button>
      </div>
    </div>
    <hr />
    <b-table striped bordered hover :items="dts" :fields="columns">
      <template slot="actions" slot-scope="row">
        <div
          class="w-100 text-center"
          v-if="user.type === 'SUPER' || user.type === 'FULL' || user.type === 'ADMIN' || user.type === 'DT'"
        >
          <b-button
            v-if="user.id != row.item.user_id || (user.type === 'SUPER' || user.type === 'FULL' || user.type === 'ADMIN')"
            @click="deleteDt(row.item.user_id)"
            variant="danger"
          >
            <i class="fa fa-trash px-1"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-form @submit.prevent="sendInvitation()">
      <b-modal ref="modalDts" :title="modalTitle">
        <div class="form-group">
          <label for="user">{{$t('enterPlayerNick')}}</label>
          <multiselect
            v-model="userSelected"
            id="user"
            label="nick"
            track-by="nick"
            open-direction="bottom"
            :options="users"
            :multiple="false"
            :searchable="true"
            :loading="loading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :options-limit="10"
            :limit="3"
            :limit-text="limitText"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="asyncFind"
            :placeholder="$t('selectOption')"
            deselectLabel
            selectLabel
            selectedLabel
            name="userSelected"
            v-validate="'required'"
            :data-vv-as="$t('user')"
            :state="validateState('userSelected')"
          ></multiselect>
        </div>
        <template slot="modal-footer">
          <b-button type="button" @click="$refs.modalDts.hide()">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('sendInvitation') }}</b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dts: [],
      users: [],
      userSelected: null,
      type: "",
      modalTitle: "",
      columns: [
        { key: "nick", label: "Nick", sortable: true },
        { key: "position", label: this.$t("position"), sortable: true },
        { key: "actions", label: "" }
      ],
      teamId: this.$route.params.teamId,
      hideDt: false,
      hideCoDt: false
    };
  },
  computed: {
    ...mapGetters(["loading", "lang", "console", "team", "user"])
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    deleteDt(userId) {
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/delete-dt/${userId}`;
      this.$axios.delete(path).then(() => {
        this.dts = this.dts.filter(dt => {
          return dt.user_id !== userId;
        });
        this.$toastr.success(this.$t("updatedInfo"), window.TITLE_SUCCESS);
      });
    },
    sendInvitation() {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            user_id: this.userSelected.id,
            position: this.type.toUpperCase()
          };
          const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/send-invitation-dts`;
          this.$axios.post(path, payload).then(() => {
            this.$toastr.success(this.$t("updatedInfo"), window.TITLE_SUCCESS);
            this.$refs.modalDts.hide();
          });
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      });
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    asyncFind(query) {
      if (query && query.length > 2) {
        const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/find-dts`;
        this.$axios.get(path, { params: { nick: query } }).then(response => {
          this.users = response.data.data;
        });
      }
    },
    openModalDts(type) {
      this.type = type;
      if (type === "dt") {
        this.modalTitle = this.$t("add") + " DT";
      } else {
        this.modalTitle = this.$t("add") + " CO-DT";
      }
      this.userSelected = null;
      this.$refs.modalDts.show();
    },
    fetchData() {
      const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}/managments`;
      this.$axios.get(path).then(response => {
        response.data.data.map(u => {
          if (u.user_id === this.user.id && u.position === "DT") {
            this.user.type = "DT";
          }
          return u;
        });
        this.dts = response.data.data;
        const dts = this.dts.filter(d => d.position === 'DT')
        if (dts.length < 1) {
          this.hideDt = true;
        }
        const codts = this.dts.filter(user => {
          return user.position === "CODT";
        });
        if (codts.length < 1) {
          this.hideCoDt = true;
        }
      });
    }
  }
};
</script>
